<template>
  <div class="pa-0">
    <div class="mt-15 px-8" v-if="isLoad">
      <v-row class="px-15">
        <v-col cols="12" v-for="i in 5" :key="i" class="text-left">
          <v-skeleton-loader type="paragraph" class="mt-3"></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <v-col
      cols="11"
      class="pa-0 mt-10 py-10"
      v-else-if="items.length === 0 && itemsOld.length === 0">
      <v-row>
        <v-col cols="12" sm="6">
          <img
            src="@/assets/images/405.png"
            @error="
              $event.target.src = 'https://admin.bpdlh.id/assets/empty.png'
            "
            width="100%" />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="text-black flex flex-wrap content-center">
          <v-col cols="12" class="text-3xl font-bold pl-0">{{
            bahasa
              ? "Tidak Ada Acara Layanan yang Tersedia"
              : "No Event Data Available"
          }}</v-col>
          <v-btn class="btn-primary mt-8" :to="{ name: 'home' }">
            {{ bahasa ? "kembali ke beranda" : "back to home" }}
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <!-- Upcoming events -->
    <div
      class="py-10 white mt-10"
      v-if="items.length > 0 || itemsOld.length > 0">
      <div class="grid justify-items-center mt-10 pa-0">
        <v-col cols="11" class="pa-0">
          <v-row class="px-3 mb-5">
            <v-col cols="12" class="text-left">
              <div class="font-bold text-3xl mb-8" v-if="bahasa">Acara</div>
              <div class="font-bold text-3xl mb-8" v-else>Events</div>
              <div class="font-bold text-xl" v-if="bahasa">Acara mendatang</div>
              <div class="font-bold text-xl" v-else>Upcoming events</div>
            </v-col>
          </v-row>
        </v-col>
      </div>
      <div v-if="items.length > 0">
        <slick ref="slick" :options="slickOptionsEvent" class="px-10">
          <div
            v-for="(i, indexEvent) in items"
            :key="'itemEvent-' + indexEvent"
            class="px-6 text-left cursor-pointer"
            @click="goTo('event-detail', i.id)">
            <img
              :src="baseUrlImage + i.image.file_path"
              @error="
                $event.target.src = 'https://admin.bpdlh.id/assets/empty.png'
              "
              width="100%"
              class="rounded-md h-52 img-card-event" />
            <div class="font-bold text-base sm:text-xl py-2">
              {{ i.name || "-" }}
            </div>
            <div class="text-sm pb-2 pa-0">
              {{
                i.start_date === i.end_date
                  ? i.start_date
                  : i.start_date + " - " + i.end_date
              }}
            </div>
            <div class="font-lora pa-0 text-base h-24 sm:h-14 word-break">
              <div v-html="strLimit(i.desc, 200)" />
            </div>
          </div>
        </slick>
        <button class="btn-white-outlined next-btn-event">
          <v-icon color="black">arrow_back</v-icon>
        </button>
        <button class="btn-white-outlined back-btn-event">
          <v-icon color="black">arrow_forward</v-icon>
        </button>
      </div>
      <div class="grid justify-items-center mt-10 pa-0">
        <v-col cols="11" class="pa-0">
          <div class="font-bold text-xl my-6 text-left pl-2" v-if="bahasa">
            Acara yang sudah berlalu
          </div>
          <div class="font-bold text-xl my-6 text-left pl-2" v-else>
            Past events
          </div>
          <v-skeleton-loader
            type="paragraph"
            class="mt-5"
            v-if="isLoadOld"></v-skeleton-loader>
          <v-row class="mt-5" v-else>
            <v-col
              cols="12"
              sm="4"
              v-for="(i, idxOld) in itemsOld"
              :key="'old' + idxOld"
              class="px-5 text-left cursor-pointer"
              @click="goTo('event-detail', i.id)">
              <img
                :src="i.image ? baseUrlImage + i.image.file_path : '-'"
                width="100%"
                @error="
                  $event.target.src = 'https://admin.bpdlh.id/assets/empty.png'
                "
                class="rounded-md h-52 img-card-event" />
              <div class="font-bold text-base sm:text-xl py-2">
                {{ i.name || "-" }}
              </div>
              <div class="text-sm pb-2 pa-0">
                {{
                  i.start_date === i.end_date
                    ? i.start_date
                    : i.start_date + " - " + i.end_date
                }}
              </div>
              <div class="text-base font-lora pt-1 pb-2 pa-0 word-break">
                <div v-html="strLimit(i.desc, 150)" />
              </div>
            </v-col>
          </v-row>
          <v-row class="text-left mt-3 py-10">
            <v-spacer />
            <v-col cols="auto">
              <v-pagination
                v-model="page"
                :length="pageCount"
                color="green darken-1"
                next-icon="navigate_next"
                prev-icon="chevron_left"></v-pagination>
            </v-col>
          </v-row>
        </v-col>
      </div>
    </div>
  </div>
</template>
<script>
import Slick from "vue-slick";
import "slick-carousel/slick/slick.css";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  props: {
    bahasa: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Slick,
  },
  data() {
    return {
      isLoad: false,
      isLoadPast: false,
      items: [],
      itemsList: [1, 2, 3, 4],
      itemsPast: [],
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlImage: process.env.VUE_APP_BASE_ASSET,
      page: 1,
      pageCount: 1,
      slickOptionsEvent: {
        slidesToShow: 3,
        prevArrow: ".next-btn-event",
        nextArrow: ".back-btn-event",
        infinity: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      slickOptionsXS: {
        slidesToShow: 1,
        prevArrow: ".next-btn-xs",
        nextArrow: ".back-btn-xs",
      },
      itemsOld: [],
      isLoadOld: false,
    };
  },
  watch: {
    bahasa() {
      this.getDataNew();
      this.getDataOld();
    },
    page() {
      this.getDataOld();
    },
  },
  computed: {
    language() {
      return this.bahasa ? "ID" : "ENG";
    },
  },
  mounted() {
    this.getDataNew();
    this.getDataOld();
  },
  methods: {
    async getDataNew() {
      this.isLoad = true;
      let param = {
        lang: this.language,
        option: "NEW",
      };
      await axios
        .get(this.baseUrl + "/event", { params: param })
        .then((response) => {
          if (response.data.code === 200) {
            this.items = response.data.data;
          }
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoad = false));
    },
    async getDataOld() {
      this.isLoadOld = true;
      let param = {
        lang: this.language,
        option: "OLD",
        page: this.page,
      };
      await axios
        .get(this.baseUrl + "/event", { params: param })
        .then((response) => {
          if (response.data.code === 200) {
            this.itemsOld = response.data.data.data;
            this.page = response.data.data.current_page;
            this.pageCount = response.data.data.last_page;
          }
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoadOld = false));
    },
    goTo(link, param) {
      this.$router.push({
        name: link,
        params: {
          id: param,
        },
      });
    },
    next() {
      this.$refs.slick.next();
    },
    prev() {
      this.$refs.slick.prev();
    },
    reInit() {
      // Helpful if you have to deal with v-for to update dynamic lists
      this.$nextTick(() => {
        this.$refs.slick.reSlick();
      });
    },
    descLimit(val, max) {
      const s = "<h1>Remove all <b>html tags</n></h1>";
      s.replace(new RegExp("<[^>]*>", "g"), "");
      console.log(max);
      console.log(val.replace(new RegExp("<[^>]*>", "g"), ""));
      return val;
    },
    // Events listeners
    handleAfterChange(event, slick, currentSlide) {
      console.log("handleAfterChange", event, slick, currentSlide);
    },
    handleBeforeChange(event, slick, currentSlide, nextSlide) {
      console.log("handleBeforeChange", event, slick, currentSlide, nextSlide);
    },
    handleBreakpoint(event, slick, breakpoint) {
      console.log("handleBreakpoint", event, slick, breakpoint);
    },
    handleDestroy(event, slick) {
      console.log("handleDestroy", event, slick);
    },
    handleEdge(event, slick, direction) {
      console.log("handleEdge", event, slick, direction);
    },
    handleInit(event, slick) {
      console.log("handleInit", event, slick);
    },
    handleReInit(event, slick) {
      console.log("handleReInit", event, slick);
    },
    handleSetPosition(event, slick) {
      console.log("handleSetPosition", event, slick);
    },
    handleSwipe(event, slick, direction) {
      console.log("handleSwipe", event, slick, direction);
    },
    handleLazyLoaded(event, slick, image, imageSource) {
      console.log("handleLazyLoaded", event, slick, image, imageSource);
    },
    handleLazeLoadError(event, slick, image, imageSource) {
      console.log("handleLazeLoadError", event, slick, image, imageSource);
    },
  },
};
</script>
<style></style>
